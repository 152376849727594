.listing-nav-steps {
  border-width: 1px;
  border-radius: 40px;
  border-color: #e6e6e6;
  max-width: 35rem;
  margin: 0 auto;
  height: max-content;
  font-family: "Poppins", sans-serif !important;
  padding-top: 0 !important;
}
.listing-nav-steps .ant-steps-item-active .ant-steps-item-icon,
.listing-nav-steps .ant-steps-item-finish .ant-steps-item-icon {
  background: rgba(255, 125, 18, 0.1);
  border: 1px solid #ff7d12;
}

.listing-nav-steps .ant-steps-item-wait .ant-steps-item-icon {
  background: transparent;
  border: 1px solid #6f6e6d;
}

.listing-nav-steps .ant-steps-item-active {
  border: 1px solid#ff7d12;
  border-radius: 32px;
}

.listing-nav-steps
  .ant-steps-item-active
  .ant-steps-item-icon
  > .ant-steps-icon,
.listing-nav-steps.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #ff7d12;
}

.listing-nav-steps.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  svg {
  margin-bottom: 5px;
}

.listing-nav-steps.ant-steps.ant-steps-navigation .ant-steps-item::before,
.listing-nav-steps.ant-steps.ant-steps-navigation .ant-steps-item::after {
  content: none;
}

.listing-nav-steps .ant-steps-item-container {
  padding: 10px 32px !important;
}

.listing-nav-steps.ant-steps
  .ant-steps-item-active
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #ff7d12;
  font-weight: 500;
}

.listing-nav-steps.ant-steps.ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-content {
  min-height: max-content !important;
}

.preview-modal .ant-modal-footer {
  display: flex;
  justify-content: space-between;
}

.preview-modal .ant-btn-primary {
  background-color: #ff7d12;
  color: #fff;
}
.preview-modal .ant-btn-primary:hover,
.preview-modal .ant-btn-primary:focus {
  background-color: #ff7d128c;
}
.preview-modal .ant-btn-default:hover,
.preview-modal .ant-btn-default:focus {
  border-color: #ff7d12;
  color: #ff7d12;
}

.preview-modal .ant-btn {
  font-family: "Poppins", sans-serif;
  padding: 6px 20px;
  display: inline-flex;
  align-items: center;
}
.ant-tooltip-content {
  display: none;
}
.ant-select-selection-item {
  font-size: 1.1em;
}
.floating-input input {
  color: #000000;
}
.upload-note {
  font-size: 10px;
  color: red;
}
