.admin-sider {
  background: #121212 !important;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.admin-sider .logout {
  color: #ff3b30;
  position: absolute;
  bottom: 24px;
  transition: all 1s ease-in-out;
}

.admin-sider .logout:hover {
  color: #fbfcfc;
}

.admin-sider .logout svg {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
}

.admin-sider .logout .title {
  display: inline-block;
}

.admin-sider.ant-layout-sider-collapsed .logout .title {
  display: none;
}

.menuitemss {
  color: #1F1A16;
}

.menuitemss .ant-menu-item .admin-sider .logout {
  margin-top: 1rem;
  padding-inline: 14px;
  padding-top: 4px;
  margin-left: 8px;
}

.menuitemss .ant-menu-item:focus,
.menuitemss .ant-menu-item:focus-visible,
.menuitemss .ant-menu-item:focus-within,
.menuitemss .ant-menu-item:active {
  background-color: transparent !important;
}

.menuitemss .ant-menu-item .ant-menu-title-content,
.admin-sider .logout {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}

.admin-sider .trigger {
  display: flex;
  padding: 1rem 1rem 0;
  justify-content: flex-end;
}

.admin-sider .trigger-icon {
  font-size: 1.5rem;
}

.ant-layout .ant-layout-sider {
  border-left: 1px #E5DEDE solid;
  border-right: 1px #E5DEDE solid;
  border-bottom: 1px #E5DEDE solid;
  border-top-color: transparent;
  border-radius: 0 !important;
  background: white !important;
}

.ant-layout:hover .ant-layout-sider:hover {
  border-left: 1px #E5DEDE solid;
  border-right: 1px #E5DEDE solid;
  border-bottom: 1px #E5DEDE solid;
  border-top-color: transparent;
  border-radius: 0 !important;
  background: white !important;
}

.trigger-icon {
  color: #ff7d12;
  width: 24px !important;
  height: 24px !important;
}

.admin-sider .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #FFF8F3 !important;
  border-radius: 0 !important;
  /* width: 100%; */
}

.admin-sider .ant-menu.ant-menu-dark .ant-menu-item:hover {
  background-color: #FFF8F3 !important;
  border-radius: 0 !important;
  color: #ff7d12;
  /* width: 100%; */
}

.admin-sider .ant-menu-item-selected {
  color: #ff7d12;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span,
.ant-menu-dark .ant-menu-item-selected .anticon+span {
  color: #ff7d12;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  width: 100% !important;
}

.ant-card-head {
  border-bottom: none;
}

.cards {
  grid-template-columns: repeat(auto-fit, minmax(100px, 3fr));
}

.ant-menu-dark .ant-menu-item-selected>span>a {
  color: #ff7d12;
}

.ant-menu-inline .ant-menu-item {
  margin-inline: 0px !important;
  width: calc(100% - 0px) !important;
}

.aboutus {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: justify;
  opacity: 0.7;
  color: #161616;
}

.ant-card-head-title {
  color: #34c759 !important;
}

.iconPlaceholder {
  padding: 20px 0;
  text-align: center;
}

.out-icon-placeholder {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-top: -50px;
  margin-left: 70px;
}

@media screen and (min-width: 1000px) {
  .admin-sider {
    min-width: 15rem !important;
    max-width: 15rem !important;
  }

  .menuitemss .ant-menu-item,
  .admin-sider .logout {
    margin-top: 0;
    padding-inline: 24px;
    height: 60px;
  }

  .admin-sider .trigger {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .admin-sider {
    min-width: 17.75rem !important;
    max-width: 17.75rem !important;
  }
}