.login-facebook-text {
    background-color: #3b5998;
    color: #fff;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 46px;
    text-align: center;
    width: 100%;
    height: 36px;
    border: 1px solid #3b5998;
    font-family: "Poppins";
    border-radius: 4px;
    -webkit-text-size-adjust: 100%;
    float: left;
    clear: none;
}
.facebook-icon {
    display: unset !important;
    padding-left: 6px;
}
.container-text{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    white-space: nowrap;
}
  
.border-text{
    border-bottom: 1px solid black;
    width: 100%;
}
  
.content-text{
    font-family: "Poppins";
    padding: 10px; 
}

@media screen and (max-width : 1204px) {
    .login-facebook-text {
        font-size: 7px;
    }
}
