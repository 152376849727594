@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Poppins", monospace;
}

img {
  display: inline;
  max-width: none !important;
  min-width: none !important;
}

.sendbbn {
  max-width: 100% !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.profile-dropdown {
  padding: 0 !important;
}

.profile-dropdown li {
  padding: 16px 24px !important;
  color: #121212 !important;
  border-bottom-width: 1px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: #c4c4c4;
}

.profile-dropdown li:last-child {
  border-bottom-width: 0px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.profile-dropdown li:hover {
  background-color: #ff7d12 !important;
  color: #ffffff !important;
}

.location-select .ant-select-selection-item {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #121212;
}

.location-select .ant-select-arrow {
  color: #121212;
  right: -4px;
}

.floating-input label {
  width: max-content;
  height: max-content !important;
  background-color: #fff;
  top: 10px;
  left: 12px;
  padding: 0 4px;
}

.floating-input:has(input:focus) label {
  background-color: #fbfcfc;
}

.floating-input input {
  padding: 12px 16px;
  height: auto;
  box-shadow: none;
  height: 46px !important;
}

.floating-input input[type="textarea"] {
  display: inline-flex !important;
}

.floating-input input[type="textarea"],
.floating-input:has(input[type="textarea"]) {
  height: 10rem !important;
}

.floating-input {
  height: 48px !important;
  box-shadow: none;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border-width: 1px;
  border-color: #dcdbdd;
  border-radius: 9999px !important;
}

.floating-input:hover,
.textarea-input textarea:hover {
  border-color: rgb(96 165 250);
}

.floating-input:has(input:focus),
.input-field:has(input:focus) .ant-picker {
  border-color: #ff7d12;
}

.floating-input label,
.floating-input input,
.input-field label,
.input-field input,
.textarea-input textarea {
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  color: #84818a;
  line-height: 1.57;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-input input:focus {
  border-color: #ff7d12;
}

.text-field {
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  border-width: 1px;
  border-color: #dcdbdd;
}

.terms-checkbox .ant-checkbox-checked .ant-checkbox-inner,
.terms-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #009580 !important;
  border-color: #009580 !important;
}

.terms-checkbox:hover .ant-checkbox-inner,
.terms-checkbox .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #009580 !important;
}

.input-field label,
.select-input .label,
.textarea-input .label {
  position: absolute;
  top: 11px;
  left: 12px;
  padding: 0 4px;
  color: #84818a;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  cursor: text;
  display: inline-flex;
  pointer-events: none;
  transform: none;
  height: max-content;
  line-height: 1.57;
}

.input-field .ant-picker,
.select-input .ant-select-selector {
  height: 48px !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  box-shadow: none !important;
  border: 1px solid #dcdbdd;
}

.input-field .ant-picker {
  border-radius: 9999px !important;
}

.textarea-input textarea {
  border-radius: 8px !important;
  padding: 12px 16px !important;
  box-shadow: none !important;
  border: 1px solid #dcdbdd;
  color: #121212;
}

.textarea-input textarea:focus {
  border: 1px solid #ff7d12;
}

.select-input .ant-select-selection-item {
  line-height: normal !important;
}

.select-input .ant-select-open .ant-select-selector {
  border-color: #ff7d12 !important;
}

.select-input .ant-select-selection-placeholder,
.textarea-input textarea::placeholder {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  align-items: center;
  color: black;
}

.select-input .ant-select-selector {
  overflow: auto;
}

.category-card:hover p {
  color: #fff !important;
}

.select-input:has(.ant-select-open) .label,
.select-input:has(.ant-select-selection-item:not(:empty)) .label,
.input-field:has(input:focus) label,
.input-field:has(input:not(:placeholder-shown)) label,
.textarea-input:has(textarea:focus) label,
.textarea-input:has(textarea:not(:placeholder-shown)) label {
  transform: translate3d(0, -75%, 0) scale(0.7);
  background-color: #fbfcfc;
}

.nearyou .slick-next {
  right: -36px;
}

.nearyou .slick-prev {
  left: -36px;
}

.listing-slides .slick-next {
  top: -16px;
  right: 24px;
}

.listing-slides .slick-prev {
  top: -16px;
  left: 16px;
}

.listing-slides .slick-next,
.listing-slides .slick-prev {
  width: 32px;
  height: 32px;
}

.listing-slides .slick-next::before,
.listing-slides .slick-prev::before {
  color: #ff7d12 !important;
  font-size: 32px;
}

.nearyou .slick-next,
.nearyou .slick-prev {
  width: 40px;
  height: 40px;
}

.nearyou .slick-next::before,
.nearyou .slick-prev::before {
  color: #ff7d12 !important;
  font-size: 40px;
}

.popular .slick-list {
  padding: 1.5rem 0;
}

.message-alert .ant-message-notice-content {
  padding: 12px 20px;
  display: inline-flex;
}

.message-alert .ant-message-custom-content {
  display: inline-flex;
  align-items: center;
}

.message-alert .ant-message-custom-content svg {
  font-size: 28px;
}

.listing-collapse {
  background: none;
  margin: 2rem 0;
}

.listing-collapse .ant-collapse-item {
  border: none !important;
}

.listing-collapse .ant-collapse-header {
  flex-direction: row-reverse;
  border-radius: 4px !important;
  padding: 12px 20px !important;
  background-color: #fff;
  margin-top: 1rem;
}

.listing-collapse .ant-collapse-header-text {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #393939;
}

.listing-collapse .ant-collapse-content-box {
  padding: 0 !important;
  margin-top: 20px !important;
}

.filter-drawer .ant-drawer-content-wrapper {
  max-width: 42rem;
}

.filter-drawer .ant-drawer-body {
  padding: 2rem;
}

.filter-drawer .ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #ff7d12;
}

.chat-drawer .ant-drawer-body {
  padding: 0;
}

.spinner .ant-spin-dot-item {
  width: 32px;
  height: 32px;
  background-color: #ff7d12;
}

.spinner .ant-spin-dot {
  font-size: 0;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.ant-select-arrow {
  color: black !important;
}

.bouncing-loader>div {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

.ad-menu .ant-dropdown-menu-item {
  padding: 10px 12px !important;
}

.header-text {
  color: #121212;
  font-weight: 600;
}

h4.header-text {
  font-size: 18px;
}

.faq-container .ant-collapse-item {
  border: 1px solid rgba(135, 131, 128, 0.3);
  background-color: #fff;
  border-radius: 24px !important;
  font-family: "Poppins" !important;
  margin-bottom: 24px;
}

.faq-container .ant-collapse-item:last-child {
  border-bottom: 1px solid rgba(135, 131, 128, 0.3);
}

.faq-container .ant-collapse-item-active {
  border: none !important;
  box-shadow: 0px 10px 38px rgba(29, 22, 17, 0.05);
}

.faq-container .ant-collapse-content-box {
  padding: 0px 44px 16px !important;
}

.faq-container .ant-collapse-header {
  align-items: center !important;
  padding: 44px !important;
}

.faq-container .ant-collapse-header-text {
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #1f1a16 !important;
}

.how-it-works-tabs .ant-tabs-nav::before {
  content: none;
}

.how-it-works-tabs .ant-tabs-nav-list {
  justify-content: center;
}

.how-it-works-tabs .ant-tabs-ink-bar {
  display: none;
}

.how-it-works-tabs .ant-tabs-nav-list .ant-tabs-tab {
  padding: 16px 32px;
  border: 1px solid #ff7d12;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.how-it-works-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #ff7d12;
}

.how-it-works-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 16px;
  font-weight: 500;
}

.how-it-works-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.how-it-works-tabs .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: 0;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-10px);
  }
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 0.4s;
}

@media screen and (max-width: 1300px) {
  .listing-slides .slick-next {
    right: -32px;
    top: 50%;
  }

  .listing-slides .slick-prev {
    left: -32px;
    top: 50%;
  }
}

@media screen and (max-width: 960px) {
  .faq-container .ant-collapse-content-box {
    padding: 0px 32px 12px !important;
  }

  .faq-container .ant-collapse-header {
    padding: 32px !important;
  }

  .faq-container .ant-collapse-header-text {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 590px) {
  .search-bar {
    display: none;
  }

  .listing-slides .slick-next {
    right: -24px;
  }

  .listing-slides .slick-prev {
    left: -24px;
  }

  .listing-slides .slick-next,
  .listing-slides .slick-prev {
    width: 24px;
    height: 24px;
  }

  .listing-slides .slick-next::before,
  .listing-slides .slick-prev::before {
    font-size: 24px;
  }

  .nearyou .slick-next {
    right: -24px;
  }

  .nearyou .slick-prev {
    left: -24px;
  }

  .nearyou .slick-next,
  .nearyou .slick-prev {
    width: 32px;
    height: 32px;
  }

  .nearyou .slick-next::before,
  .nearyou .slick-prev::before {
    color: #ff7d12 !important;
    font-size: 32px;
  }

  .filter-drawer .ant-drawer-body,
  .filter-drawer .ant-drawer-header {
    padding: 1rem;
  }

  .faq-container .ant-collapse-content-box {
    padding: 0px 28px 8px !important;
  }

  .faq-container .ant-collapse-header {
    padding: 28px !important;
  }

  .faq-container .ant-collapse-header-text {
    font-size: 18px !important;
  }

  .how-it-works-tabs .ant-tabs-nav-list .ant-tabs-tab {
    padding: 14px 20px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .how-it-works-tabs .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .how-it-works-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 15px;
  }
}

@media screen and (min-width: 900px) {
  .menuButton .anticon {
    display: none;
  }
}

.ant-image {
  display: flex !important;
}