.ant-input-group-addon {
  border: none;
  background: #fff;
  border-radius: 10px;
}
.ant-input {
  border: none;
}

.ant-select-arrow {
  color: #fff;
}
.ant-layout-header {
  background: none !important;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #ff7d12 !important;
}

.ant-layout,
.ant-layout-footer {
  background: #ffffff;
}
.button {
  background: #ff7d12;
  border: 1px solid #ff7d12;
  border-radius: 5%;
}
.button:hover {
  background: #ff7d12;
  border: 1px solid #ff7d12;
}
.ant-input-group,
.ant-input {
  height: 40px;
}
.ant-divider-horizontal {
  margin: auto;
}
.menu {
  padding: 15px !important;
  font-size: 14px;
  line-height: 17px;
  vertical-align: top;
  margin: 1.15%;
  margin-left: 2.5%;
}
.ant-menu {
  /* margin: auto 3%; */
  background: none;
  border-bottom-style: "none !important";
}

/* Content */
.ant-layout-content {
  padding: 0 50px;
}
.popular-text {
  /* left: 140px; */
  top: 196px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  padding: 3% 0 0 0 !important;
  color: #121212;
}
.pop-box {
  /* position: absolute; */
  margin: auto;
  /* top: 267px; */
}
.brow-box {
  background: black;
  /* border: 1px solid #000; */
}
.contact-box {
  margin: 1% auto;
}
.brow_name {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 32px;

  color: #ffffff;
}
.contact-box:first-child {
  background: #ff7d12;
  width: 200px;
}
.btn-contact {
  align-items: flex-start;
  padding: 14px 35px;
  gap: 10px;
  width: 125px;
  height: 47px;
  left: 379px;
  top: 1613px;
  background: #ffffff;
  border: 1px solid #fff;
}

.box {
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 20px 0;
  height: fit-content;
  padding: 10px;
  width: 100%;
  min-height: 2px;
  border: 0.953873px solid rgba(255, 125, 18, 0.24);
  color: #000;
  border-radius: 16px;
}

.vendor-box {
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 20px 0;
  height: fit-content;
  padding: 10px;
  width: 100%;
  min-height: 2px;
  border: 0.953873px solid rgba(255, 125, 18, 0.24);
  color: #000;
  border-radius: 16px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.popubox {
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  height: fit-content;
  width: 100%;
  min-height: 200px;
  color: #000;
  border-radius: 16px;
}

.popbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 20px 0;
  height: fit-content;
  padding: 10px;
  width: 100%;
  min-height: 200px;
  border: 0.953873px solid rgba(255, 125, 18, 0.24);
  color: #000;
  border-radius: 16px;
}
.box:hover {
  background: #ff7d12;
  color: #fff;
}
.nearyou-bg {
  /* height: 100%; */
  min-height: 240px;
  background-repeat: no-repeat;
  background-size: 80%;
  margin-left: 0.2%;
}
.contitle {
  /* width: 694px; */
  height: 44px;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 44px;
  color: #ffffff;
}
.conscale {
  /* width: 781px; */
  height: 72px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  /* or 150% */
  text-align: center;
  color: #121212;
}
.scaleform {
  width: 596px;
  margin: auto;
  height: 54px;
}
.connectbox {
  background: #ff7d12;
  border-radius: 40px;
  /* background: url("../../assets/background.svg") no-repeat; */
}
.imagebox {
  /* background: url("../../assets/background.svg") no-repeat; */
  width: 70%;
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.forminput {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.emailmebtn {
  background: "#000";
  color: "#fff";
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
@media (max-width: 767px) {
  .conscale {
    display: none;
  }
  .contitle {
    height: auto !important;
  }
  .scaleform {
    width: auto !important;
  }
  .contactussection {
    display: none;
  }
}

.text-block {
  /* position: absolute; */
  /* bottom: 20px; */
  color: white;
  /* position: absolute; */
  text-align: center;
  /* top: 40%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  padding-left: 20px;
  padding-right: 20px;
}
/* .container {
  position: relative;
  font-family: Arial;
} */
.mid {
  max-width: 100%;
}
.box img {
  width: 100%;
}
.popubox img {
  width: 100%;
}
.subtext {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 4px;
  color: #000;
  /* identical to box height */
}
.contact-us {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: white;
}
.slider-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #6f6e6d;
  padding-left: 10px;
  text-overflow: clip;
  overflow: hidden;
  /* margin-top: 10px; */
}
.slider-text:hover {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #6f6e6d;
}
.price-slider {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ff7d12;
  margin-top: -7%;
  line-height: 2px;
  padding-left: 10px;
}
.price-slider:hover {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ff7d12;
}
.ournewsletter {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #ffffff;
}
.consign {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 44px;
  color: #ff7d12;
}
.card {
  box-shadow: 0 4px 8px 0 #ccc, #ccc;
  border: 1px solid #fff;
  border-radius: 10px;
}
.clean {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 41.9355px;
  line-height: 59px;
  width: 500px;

  /* Colors/Text/Dark/#1A202C/100% */

  color: #1a202c;
}
.det-button {
  background: #fff;
  color: #000;
  border: 1px solid #fff;
  border-radius: 5px;
  display: flex;
  filter: drop-shadow(0px 4px 70px rgba(91, 91, 91, 0.05));
}
.det-fav {
  background: #fff;
  color: #000;
  border: 1px solid #fff;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 70px rgba(91, 91, 91, 0.05));
}
.det-fav:hover {
  background: #ff7d12;
  outline: none;
}
.det-button:hover {
  background: #ff7d12;
  outline: none;
}
.price-wrapper {
  background: coral;
}
.product-detail-tiles {
  margin: 5% auto;
}
.booknow {
  padding: 12px 37px;
  gap: 10px;
  /* width: 154px; */
  height: 48px;
  background: #ff7d12;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  border: 1px solid #ff7d12;
  flex-grow: 0;
  width: 164px;
  font-size: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.booknow-outline {
  padding: 12px 37px;
  gap: 10px;
  /* width: 154px; */
  font-weight: 600;

  height: 48px;
  background: #fff;
  border-radius: 8px;
  color: #ff7d12;
  border: 1px solid #ff7d12;
  flex-grow: 0;
}

.default-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #171313;
}
.orange-big-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ff7d12;
}

.title-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  color: #121212;
}
.terms {
  align-items: center;
  padding: 12px 20px;
  height: 48px;
  left: 140px;
  top: 984px;
  background: #ffffff;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #393939;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding-right: 20px;
}
.breadcrumb {
  background: none;
}
.menu {
  margin-left: 4% !important;
}
.breadcrumb-item a {
  padding-right: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #121212;
  font-family: "Poppins", sans-serif;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.title-sub {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #c1c1c1;
}
.filter-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #121212;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.filter-text-next {
  margin-left: 10%;
}
.top-right {
  position: absolute;
  top: 35px;
  right: 30px;
  background: #231515;
  mix-blend-mode: normal;
  opacity: 0.4;
  border-radius: 6.52566px;
}
.ant-modal-header {
  border-radius: 20px !important;
  border-bottom: none !important;
}

.generic-modal {
  margin-top: 1rem;
}

.generic-modal .ant-modal-content {
  border-radius: 2rem !important;
  background-color: #fbfcfc !important;
  padding: 2.25rem 2rem !important;
}

.generic-modal .ant-modal-close:hover,
.generic-modal .ant-modal-close:active {
  background-color: transparent;
}

.generic-modal .ant-modal-close-icon svg {
  color: #84818a;
  width: 20px;
  height: 22px;
}

.generic-modal .ant-modal-close-icon svg:hover,
.generic-modal .ant-modal-close-icon svg:active {
  color: #000;
}

.ant-modal-content {
  border-radius: 20px !important;
}
.ant-modal-footer {
  text-align: left !important;
  border-top: none !important;
}

.ant-form-item {
  margin: 0;
}
.ant-checkbox-input input:checked ~ .checkmark {
  background-color: #000;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  border-bottom: 2px solid #ff7d12;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: #ff7d12 !important;
}

.ant-input {
  color: #121212;
  opacity: 0.5;
}
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 1.5rem !important;
} */
.link {
  color: #121212;
  opacity: 0.6;
}
.link:hover {
  color: #ff7d12;
  opacity: 1;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #ff7d12 !important;
}
.contactusbtn {
  width: 30%;
  background: #fff;
  border: 1px solid #fff;
  color: #ff7d12;
  height: 40px;
  float: left !important;
  border-radius: 5px;
  font-weight: 700;
}
.copyright {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1a202c;
  margin-top: 3%;
}
.btn-outline-secondary {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  text-transform: none !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500 !important;
}
.submit-btn {
  width: 40%;
  background: #fff;
  border: 1px solid #fff;
  color: #000;
  height: 40px;
  border-radius: 10px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}

@media (max-width: 767px) {
  .ant-space-item {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    width: 80px;
  }
  .sort-by {
    display: none;
  }
}
